#ct-container {
	position: fixed;
	width: 100%;
	height: 100vh;
	top: 30px;
	left: 0;
	z-index: 2000;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	pointer-events: none;
	overflow: hidden;
	box-sizing: border-box;
}

.ct-row {
	display: flex;
	justify-content: space-between;
}

.ct-group {
	flex: 1;
	margin: 10px 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.ct-group:first-child {
	align-items: flex-start;
}

.ct-group:last-child {
	align-items: flex-end;
}

.ct-flex-bottom {
	justify-content: flex-end;
}

.ct-toast {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 15px 20px;
	background-color: #fff;
	box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
	color: #000;
	border-radius: 4px;
	margin: 0px;
	opacity: 1;
	transition: 0.3s all ease-in-out;
	min-height: 45px;
	pointer-events: all;
	cursor: pointer;
}

.ct-toast:focus {
	outline: none;
}

.ct-text-group {
	margin-left: 15px;
}

.ct-text-group-heading {
	margin-left: 25px;
}

.ct-heading {
	font-size: 18px;
	margin: 0px;
	margin-bottom: 5px;
}

.ct-text {
	font-size: 14px;
}

@media (max-width: 768px) {
	.ct-row {
		justify-content: flex-start;
		flex-direction: column;
		margin: 7px 0px;
	}

	.ct-group {
		flex: none;
		margin: 0px;
	}

	.ct-toast {
		margin: 8px 15px;
		width: initial;
	}
}
* {
  /* padding: 0; */
  /* margin: 0; */
  box-sizing: border-box;
  /* font-size: 15px; */
}

.quill * {
  padding: initial;
  margin: initial;
  box-sizing: border-box;
  font-size: initial;
}

body {
  font: 14px/22px 'Hind', sans-serif;
  margin: 0;
}

input {
  font-size: 15px;
}

@media screen and (min-width: 321px) {
  body {
    font-size: 15px;
  }
}

@media screen and (min-width: 478px) {
  body {
    font-size: 15px;
  }
}


::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #b2b9c4;
}

::-webkit-scrollbar-thumb {
  background-color: #b2b9c4;
}

.form-container {
  display: grid;
  gap: 15px;
}

.strong {
  font-weight: 600;
}

.avatars {
  width: 150px;
}

@media screen and (min-width: 769px) {
  .avatars {
    width: 250px;
  }
}

.maintenance {
  margin: 0 auto;
  display: grid;
  place-items: center;
}

.ql-container {
  height: 400px !important;
  overflow: auto;
}

button {
  outline: none;
  border: none;
}